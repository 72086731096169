import { AxiosError } from 'axios'
import { ReactNode, useEffect, useState } from 'react'

import * as style from './error.css'

interface Props {
  error: AxiosError | undefined
}

function Error({ error }: Props) {
  const [errorMessage, setErrorMessage] = useState<ReactNode>(null)

  useEffect(() => {
    if (error?.response) {
      if (error.response.status === 401) {
        setErrorMessage(
          <>
            사용자 인증정보가
            <br />
            변경되었습니다.
          </>
        )
      } else {
        setErrorMessage(
          <>
            일시적으로 서비스를
            <br />
            이용할 수 없습니다.
          </>
        )
      }
    } else {
      setErrorMessage(
        <>
          일시적으로 서비스를
          <br />
          이용할 수 없습니다.
        </>
      )
    }
  }, [error])

  const refresh = () => {
    window.location.reload()
  }

  return (
    <div className={style.wrapper}>
      <p
        className={style.title}
        data-testid="error-message"
      >
        {errorMessage}
      </p>
      <p>잠시 후 다시 시도해주세요.</p>
      <button
        className={style.btn}
        onClick={refresh}
      >
        새로고침
      </button>
    </div>
  )
}

export default Error
